.titulo {
  padding-bottom: 1%;
  background-color: #26285a;
  vertical-align: middle;
  margin-right: 0;
  margin-left: 0;
}

.header {
  padding-bottom: 1%;
  background-color: #fff;
  vertical-align: middle;
  margin-right: 0;
  margin-left: 0;
}

.titulo h1 {
  padding-top: 2%;
  color: #fff;
  text-align: center;
}

.titulo-img-left {
  max-width: 200px;
  padding-top: 8%;
}

.titulo-img-right {
  max-width: 200px;
  float: right;
  padding-top: 2%;
  padding-right: 10px;
}

.instrucciones {
  text-align: center;
  color: #26285a;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0px;
}

/**********************************************Camara**************************/

.camera {
  margin-top: 5px;
  margin-bottom: 10px;
}

.photo {
  margin-top: 20px;
}

.timer {
  color: #fff;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 65px;
}

#underground {
  background-color: #222;
  position: absolute;
  top: 18px;
  right: 15px;
  opacity: 0.4;
  width: 950px;
  height: 534px;
  margin-top: 20px;
}
#overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  margin-top: 20px;
}
#overlay #center {
  width: 600px;
  height: 400px;
  background-color: #fff;
  position: absolute;
  top: 41%;
  left: 48%;
  margin-top: -125px;
  margin-left: -275px;
  border-width: 100%;
  border-color: #fff;
  border-style: solid;
  opacity: 0.3;
}

#overlay #elipse {
  width: 255px;
  height: 340px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 37%;
  left: 58%;
  margin-top: -100px;
  margin-left: -200px;
  border-width: 100%;
  border-color: #fff;
  border-style: solid;
  opacity: 0.3;
}

.camera-mobile {
  padding-top: 20px;
  display: none;
}

/*Detectar si es un ipad o dispositivo movil para que se despliege el input camera
@media only screen and (max-device-width: 768px) and (max-device-width: 1024px) {
  .camera {
    display: none;
  }

  .camera-mobile {
    display: block;
  }
}*/

/*********************************Menu***************************/

.link:hover {
  text-decoration: none;
}

.link {
  color: #242856;
}

.documento {
  max-width: 300px;
}

/* Grow */
.hvr-grow {
  display: block;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}

/*****************************Resultados**********************/
/*=======================
       C-Container
=========================*/
.c-container {
  max-width: 27rem;
  margin: 1rem auto 0;
  padding: 1rem;
}

/*=======================
       O-Circle
=========================*/

.o-circle {
  display: flex;
  width: 10.555rem;
  height: 10.555rem;
  justify-content: center;
  align-items: flex-start;
  border-radius: 50%;
  animation: circle-appearance 0.8s ease-in-out 1 forwards,
    set-overflow 0.1s 1.1s forwards;
}

.c-container__circle {
  margin: 0 auto 5.5rem;
}

/*=======================
    C-Circle Sign
=========================*/

.o-circle__sign {
  position: relative;
  opacity: 0;
  background: #fff;
  animation-duration: 0.8s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.o-circle__sign::before,
.o-circle__sign::after {
  content: "";
  position: absolute;
  background: inherit;
}

.o-circle__sign::after {
  left: 100%;
  top: 0%;
  width: 500%;
  height: 95%;
  transform: translateY(4%) rotate(0deg);
  border-radius: 0;
  opacity: 0;
  animation: set-shaddow 0s 1.13s ease-in-out forwards;
  z-index: -1;
}

/*=======================
      Sign Success
=========================*/

.o-circle__sign--success {
  background: rgb(56, 176, 131);
}

.o-circle__sign--success .o-circle__sign {
  width: 1rem;
  height: 6rem;
  border-radius: 50% 50% 50% 0% / 10%;
  transform: translateX(130%) translateY(35%) rotate(45deg) scale(0.11);
  animation-name: success-sign-appearance;
}

.o-circle__sign--success .o-circle__sign::before {
  bottom: -17%;
  width: 100%;
  height: 50%;
  transform: translateX(-130%) rotate(90deg);
  border-radius: 50% 50% 50% 50% / 20%;
}

/*--shadow--*/
.o-circle__sign--success .o-circle__sign::after {
  background: rgb(40, 128, 96);
}

/*=======================
      Sign Failure
=========================*/

.o-circle__sign--failure {
  background: rgb(236, 78, 75);
}

.o-circle__sign--failure .o-circle__sign {
  width: 1rem;
  height: 7rem;
  transform: translateY(25%) rotate(45deg) scale(0.1);
  border-radius: 50% 50% 50% 50% / 10%;
  animation-name: failure-sign-appearance;
}

.o-circle__sign--failure .o-circle__sign::before {
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateY(-50%) rotate(90deg);
  border-radius: inherit;
}

/*--shadow--*/
.o-circle__sign--failure .o-circle__sign::after {
  background: rgba(175, 57, 55, 0.8);
}

/*-----------------------
      @Keyframes
--------------------------*/

/*CIRCLE*/
@keyframes circle-appearance {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

/*SIGN*/
@keyframes failure-sign-appearance {
  50% {
    opacity: 1;
    transform: translateY(25%) rotate(45deg) scale(1.7);
  }

  100% {
    opacity: 1;
    transform: translateY(25%) rotate(45deg) scale(1);
  }
}

@keyframes success-sign-appearance {
  50% {
    opacity: 1;
    transform: translateX(130%) translateY(35%) rotate(45deg) scale(1.7);
  }

  100% {
    opacity: 1;
    transform: translateX(130%) translateY(35%) rotate(45deg) scale(1);
  }
}

@keyframes set-shaddow {
  to {
    opacity: 1;
  }
}

@keyframes set-overflow {
  to {
    overflow: hidden;
  }
}

.resultados {
  font-size: 19px;
  font-weight: 450;
}

/******************************Spinner**********************/
.loading {
  width: 100%;
  height: 100%;
  background: #16171d;
  opacity: 0.6;
  position: fixed;
  z-index: 2;
}

.loading > div {
  opacity: 1;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  top: 50%;
  margin-top: -30px;
}

.loading > div > div {
  opacity: 1;
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: #5054cf;
  top: 10px;
  left: 10px;
  transform-origin: 20px 20px;
  border-radius: 8px;
  animation: spin-a 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
}

.loading > div > .c2 {
  opacity: 1;
  top: 10px;
  left: auto;
  right: 10px;
  transform-origin: -4px 20px;
  animation: spin-b 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
}
.loading > div > .c3 {
  opacity: 1;
  top: auto;
  left: auto;
  right: 10px;
  bottom: 10px;
  transform-origin: -4px -4px;
  animation: spin-c 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
}
.loading > div > .c4 {
  opacity: 1;
  top: auto;
  bottom: 10px;
  transform-origin: 20px -4px;
  animation: spin-d 2s infinite cubic-bezier(0.5, 0, 0.5, 1);
}

@keyframes spin-a {
  0% {
    transform: rotate(90deg);
  }
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-b {
  0% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-c {
  0% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-d {
  0% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading > span {
  left: 0;
  line-height: 200px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  opacity: 1;
  font-weight: 700;
  color: #5054cf;
  margin-top: -50px;
  font-size: 21px;
}

.boton {
  color: #9b9ed0;
  border-color: #9b9ed0;
  background: rgba(255, 255, 255, 0);
}

.boton:hover {
  text-decoration-color: #fff;
  background: #9b9ed0;
  border-color: #9b9ed0;
}

.boton:active {
  text-decoration-color: #fff !important;
  background: #9b9ed0 !important;
  border-color: #9b9ed0 !important;
  outline: none;
}
